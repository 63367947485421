import styled from 'styled-components';
import * as React from 'react';
import { DolGrammarLogo } from '@doltech/ui/lib/figma/DolLogo';
import { LAYER } from '@doltech/utils/lib/constants';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { flexGap, horizontalSpace } from '@doltech/utils/lib/css-style';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import {
  useGlobalModalPortalStore,
  withGlobalModalPortal,
} from '@doltech/ui/lib/hocs/withGlobalModalPortal';
import { GrammarSideBarMenuLayout } from './components/GrammarSideBarMenuLayout';
import { GrammarMenuHeader } from './components/GrammarMenuHeader';
import { BtnHamburgerSidebar } from './components/BtnHamburgerSidebar';
import { GrammarNavigation } from './components/header/GrammarNavigation';
import { useGrammarMenu } from './hooks/useGrammarMenu';
import { useLandingGrammarFilter } from './hooks/useLandingGrammarFilter';
import { GrammarFreeUserSignUpButton } from './components/header/GrammarFreeUserSignUpButton';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import cl from 'classnames';
import { useRouter } from 'next/router';
import { landingRoutingUrls } from '@doltech/core/lib/routing/grammar/grammar-routing.config';
import dynamic from 'next/dynamic';

const GrammarSearchBarOpenComponent = dynamic(
  () => import('../search-page/components/GrammarSearchBarOpen'),
  {
    loading: () => <div />,
  }
);

const GrammarLandingTopSearchComponent = dynamic(
  () => import('./components/header/GrammarLandingTopSearch'),
  {
    loading: () => <div />,
  }
);

const Main = styled.header`
  .topbar-fake,
  .topbar {
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    align-items: center;
  }

  .topbar {
    z-index: ${LAYER.LAYER3};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${colorsV2.white100};
    box-shadow: ${shadows.zIndex1};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;

    ${fromScreen(1128)} {
      padding: 0 32px;
    }

    ${fromScreen(768)} {
      padding: 0 24px;
      grid-template-columns: repeat(3, 1fr);
    }

    &.hidden {
      display: none;
    }
  }

  .full-width-bar-search {
    &.hidden {
      display: none;
    }
  }
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(8)}
`;

const MiddleHeaderWrapper = styled.div`
  display: none;
  justify-content: center;

  ${fromScreen(768)} {
    display: flex;
  }
`;

const RightHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${horizontalSpace(16)};

  ${fromScreen(1128)} {
    width: revert;
  }

  &.avatar {
    ${horizontalSpace(12)};
  }

  .sign-up-wrapper {
    display: flex;
    align-items: center;
  }
`;

const MobileIcon = styled.div`
  ${fromScreen(768)} {
    display: none;
  }
`;

const LogoWrapperLink = styled.a`
  cursor: pointer;
`;

export const GrammarLandingPageHeaderLayout = withGlobalModalPortal((props) => {
  const { otherUrlInfo, pageType } = props;
  const { filter } = useLandingGrammarFilter();
  const { show, hide } = useGlobalModalPortalStore();
  const { topBarMenu } = useGrammarMenu();
  const router = useRouter();
  const { isLoggedIn } = useGetAuthInfo();
  const [showBarSearch, setShowBarSearch] = React.useState(false);

  const handleNavUserPage = () => {
    router.push({
      pathname: landingRoutingUrls.GRAMMAR_STUDENT_VIEW_MY_ACCOUNT,
    });
  };

  const handleShowHamburgerSidebar = () => {
    show({
      content: (
        <GrammarSideBarMenuLayout>
          <GrammarMenuHeader
            menus={topBarMenu}
            onClose={hide}
            onNavUserPage={handleNavUserPage}
            otherUrlInfo={otherUrlInfo}
          />
        </GrammarSideBarMenuLayout>
      ),
    });
  };

  return (
    <Main className="grammar-layout-header">
      <div className={cl('topbar', { hidden: showBarSearch })}>
        <LeftHeaderWrapper>
          <MobileIcon>
            <BtnHamburgerSidebar onOpen={handleShowHamburgerSidebar} />
          </MobileIcon>
          <LogoWrapperLink href={'/'}>
            <DolGrammarLogo />
          </LogoWrapperLink>
        </LeftHeaderWrapper>
        <MiddleHeaderWrapper>
          <GrammarNavigation otherUrlInfo={otherUrlInfo} pageType={pageType} />
        </MiddleHeaderWrapper>
        <RightHeaderWrapper className={cl({ avatar: isLoggedIn })}>
          <GrammarLandingTopSearchComponent
            onSearch={filter?.setSearch}
            showBarSearch={showBarSearch}
            onSearchIconClick={() => setShowBarSearch(true)}
          />
          <div className="sign-up-wrapper">
            <GrammarFreeUserSignUpButton />
          </div>
        </RightHeaderWrapper>
      </div>
      <div className="topbar-fake" />
      <GrammarSearchBarOpenComponent
        className={cl('full-width-bar-search', { hidden: !showBarSearch })}
        onClick={() => setShowBarSearch(false)}
      />
    </Main>
  );
});
