import { getCurrentAppName } from '@doltech/core/lib/auth/authManager';
import { environment } from '@doltech/core/lib/environment/environment';
import qs from 'qs';
import { ParsedUrlQuery } from 'querystring';

type Url =
  | string
  | {
      pathname?: string;
      query?: any;
    };

export function urlQueryToSearchParams(urlQuery: ParsedUrlQuery): URLSearchParams {
  const result = new URLSearchParams();
  Object.entries(urlQuery).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => result.append(key, qs.stringify(item)));
    } else if (typeof value === 'object') {
      result.set(key, qs.stringify(value));
    } else {
      result.set(key, value);
    }
  });
  return result;
}

export const mergeUrlWithParams = (url, params: ParsedUrlQuery) => {
  return `${url}?${urlQueryToSearchParams(params).toString()}`;
};

export const BLOG_SECTION_PREFIX = 'blog-section-';
export const GRAMMAR_SECTION_PREFIX = 'grammar-section-';

// an item is a Asset record in graph cms
export const getAssetLink = ({
  item,
  width,
  height,
}: {
  item: { handle: string };
  width?: number;
  height?: number;
}) => {
  if (!item || !item.handle) return null;

  // without with or height the asset will be considered as videos or files
  if (!width && !height) {
    return `${environment.GRAPH_CMS_CDN}/${item.handle}`;
  }

  if (width && !height) {
    return `${environment.GRAPH_CMS_CDN}/resize=w:${width},fit:crop/compress/${item.handle}`;
  }

  if (!width && height) {
    return `${environment.GRAPH_CMS_CDN}/resize=h:${height},fit:crop/compress/${item.handle}`;
  }

  return `${environment.GRAPH_CMS_CDN}/resize=w:${width},h:${height},fit:crop/compress/${item.handle}`;
};

export const getAssetLinkByHandle = ({
  handle,
  width,
  height,
}: {
  handle: string;
  width?: number;
  height?: number;
}) => {
  return getAssetLink({ item: { handle }, width, height });
};

export const getGoogleDriveImageThumbnail = (id) =>
  `https://drive.google.com/thumbnail?authuser=0&id=${id}`;

export const getGoogleDriveImage = (id) => `https://drive.google.com/uc?id=${id}`;

export const calculateTableOfContent = (tocForView = []) => {
  const h2Element = Array.prototype.slice.call(document.getElementsByTagName('h2'));
  h2Element.forEach((element, index) => {
    let { nextSibling } = element;
    let id = '';
    if (index < tocForView.length) {
      id = tocForView[index].id;
    }
    element.setAttribute('id', id);
    let chilIndex = 0;
    while (nextSibling && nextSibling.tagName !== 'H2') {
      const { tagName } = nextSibling;
      if (tagName === 'H3') {
        const subId = tocForView[index]?.sub?.[chilIndex]?.id;
        if (subId) {
          nextSibling.setAttribute('id', subId);
        }
        chilIndex++;
      }
      nextSibling = nextSibling.nextSibling;
    }
  });
};

export const getFacebookUserAvatar = (fbUserId: string) =>
  `https://graph.facebook.com/v2.5/${fbUserId}/picture?type=large`;

export const buildUrl = (domain, path) => {
  if (path && path.includes('http')) {
    return path;
  }
  const finalUrl = `${domain}/${path}`;
  return fixRedirectUrl(finalUrl.replace(/([^:]\/)\/+/g, '$1'));
};

export const getLandingUrlFromPath = (path, useFor = '') => {
  const appName = getCurrentAppName();
  switch (appName) {
    case 'landing-blog':
    case 'landing-blog-detail': {
      return buildUrl(environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME, path);
    }
    case 'landing-dictionary': {
      return buildUrl(environment.REACT_APP_DOL_DICTIONARY_WEBSITE_HOST_NAME, path);
    }
    case 'landing': {
      return buildUrl(environment.SEO_PREFIX, path);
    }
    default:
  }

  switch (useFor) {
    case 'landing-blog': {
      return buildUrl(environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME, `category/${path}`);
    }
    case 'landing-blog-detail': {
      return buildUrl(environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME, `blog/${path}`);
    }
    case 'landing-dictionary': {
      return buildUrl(environment.REACT_APP_DOL_DICTIONARY_WEBSITE_HOST_NAME, path);
    }
  }
  return buildUrl(environment.SEO_PREFIX, path);
};

export const getLandingUrlFromPathWithoutCheckingApp = (path, useFor = '') => {
  switch (useFor) {
    case 'landing-blog': {
      return buildUrl(environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME, `category/${path}`);
    }
    case 'landing-blog-detail': {
      return buildUrl(environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME, `blog/${path}`);
    }
    case 'landing-dictionary': {
      return buildUrl(environment.REACT_APP_DOL_DICTIONARY_WEBSITE_HOST_NAME, path);
    }
  }
  return buildUrl(environment.SEO_PREFIX, path);
};

export const buildReferralLink = (url, referralCode) => {
  return `${url}?referralCode=${referralCode}`;
};

export const buildInviteReferralLink = (referralCode) => {
  return environment.production
    ? buildUrl(environment.REACT_APP_REFERRAL_DOMAIN_NAME, referralCode)
    : getLandingUrlFromPath(`referred/${referralCode}`);
};

function isUrlHasProtocol(url) {
  return url.includes('http') || url.includes('https');
}

export const s3ToCDN = (url) => {
  const cdnDev = 'https://gqefcpylonobj.vcdn.cloud';
  if (!url) {
    return null;
  }
  if (url.includes('https://suijm9clouobj.vcdn.cloud')) {
    return url;
  }
  if (url.includes(environment.CDN_GET_PRIVATE_RESOURCE)) {
    return url;
  }
  if (!isUrlHasProtocol(url)) {
    return `${environment.CDN_GET_PRIVATE_RESOURCE}/${url}`;
  }
  if (!url.includes('.amazonaws.com')) {
    return url;
  }
  const path = url.split('.amazonaws.com')[1];

  if (url.includes('-resource-dev')) {
    return `${cdnDev}${path}`;
  }
  if (url.includes('/PUBLIC/')) {
    if (path.includes('?X-Amz-')) {
      const pathWithoutQueryParams = path.split('?X-Amz-')[0];
      return `${environment.CDN_GET_PUBLIC_RESOURCE}${pathWithoutQueryParams}`;
    }
    return `${environment.CDN_GET_PUBLIC_RESOURCE}${path}`;
  }

  return `${environment.CDN_GET_PRIVATE_RESOURCE}${path}`;
};

export const getFullUrl = (url) => {
  if (!url) {
    return '';
  }
  if (url.includes('http')) {
    return url;
  }
  if (url.startsWith('/')) {
    return `${environment.REACT_APP_AUTH_DOL_VN_REDIRECT_URL}${url}`;
  }
  return `${environment.REACT_APP_AUTH_DOL_VN_REDIRECT_URL}/${url}`;
};

export const parseUrlWithQuery = (pathname, query) => {
  let parsedUrl;
  let parsedPathname = pathname;
  let queryParams;

  // loop through every query key to find path variable -> parse pathname with path variable. Keep the rest of query
  Object.entries(query).forEach(([key, value]) => {
    const regex = new RegExp(`\\[${key}\\]`);

    if (regex.test(pathname)) {
      parsedPathname = parsedPathname.replace(regex, value as string);
      return;
    }

    queryParams = {
      ...(queryParams || {}),
      [key]: value,
    };
  });

  // only create parsedUrl from parsedPathname and queryParams if queryParams available
  if (queryParams) {
    parsedUrl = `${parsedPathname}?${qs.stringify(queryParams, {
      arrayFormat: 'repeat',
      skipNulls: true,
      encode: true,
    })}`;
  } else {
    parsedUrl = parsedPathname;
  }

  return parsedUrl;
};

export const getCurrentAppNameFromUrl = () => {
  const pathArray = window.location.pathname.split('/');
  const appTextIndex = pathArray.findIndex((path) => path === 'app');
  const currentAppAvailable = Boolean(appTextIndex && appTextIndex !== pathArray.length - 1);

  if (currentAppAvailable) {
    return pathArray[appTextIndex + 1];
  }

  return '';
};

export const isUrlAbsolute = (url: string) => {
  const doubleSlashIndex = url.indexOf('//');
  const colonDoubleSlashIndex = url.indexOf('://');
  const questionMarkIndex = url.indexOf('?');
  const hasQuery = questionMarkIndex > -1;

  return (
    // Example: //tuhocielts.dolenglish.vn
    doubleSlashIndex === 0 ||
    // Example: https://tuhocielts.dolenglish.vn
    (!hasQuery && colonDoubleSlashIndex > 0) ||
    // Example: https://tuhocielts.dolenglish.vn?target=http://dolenglish.vn
    (hasQuery && colonDoubleSlashIndex > 0 && colonDoubleSlashIndex < questionMarkIndex)
  );
};

export const fixRedirectUrl = (url) => {
  return url
    .replace(/([^:])(\/\/+)/g, '$1/')
    .replace(/(^|[^:])[/]{2}(?![/])/g, '$1/')
    .replace('/page/page', '/page')
    .replace('/blog/blog', '/blog')
    .replace('/category/category', '/category')
    .toLowerCase();
};

export const getLandingLinearthinkingUrl = () => {
  return `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/gioi-thieu-linearthinking`;
};

export const getCorpWebsiteUrl = (path) => {
  return fixRedirectUrl(`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/${path}`);
};

export const buildResultUrl = ({ appPrefix, url }) => {
  const prefix = appPrefix;
  let resultUrl;

  // if url is string => open url
  if (typeof url === 'string') {
    resultUrl = [prefix, url].filter(Boolean).join('');
  } else {
    // else url is not string => create an url from pathname and query
    const { pathname, query, hash } = url;
    resultUrl = [prefix, `${parseUrlWithQuery(pathname, query || {})}${hash || ''}`]
      .filter(Boolean)
      .join('');
  }
  return resultUrl;
};

export const openLinkOnNewTab = ({
  appPrefix = undefined,
  url,
  options = { target: '_blank' },
}) => {
  const { target } = options;
  const resultUrl = buildResultUrl({ appPrefix, url });
  console.log('openLinkOnNewTab', resultUrl);
  window.open(resultUrl, target);
};

export const openLink = ({ appPrefix = undefined, url }) => {
  const resultUrl = buildResultUrl({ appPrefix, url });

  window.location.href = resultUrl;
};

export const replaceLink = ({ appPrefix = undefined, url }) => {
  const resultUrl = buildResultUrl({ appPrefix, url });
  window.location.replace(resultUrl);
};

export const urlToPath = (url: string) => {
  return url.split('/').slice(3).join('/');
};

export const checkUrlValid = (url) =>
  /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?::\d{1,5})?(?:[/?#]\S*)?$/.test(
    url
  );

/*
 * UrlRoute:
 * {
 *   pathname: 'http://google.com',
 *   query: { example: 1, test: 2 }
 * }
 *
 * -> encodedRoute = eyJwYXRobmFtZSI6Imh0dHA6Ly9nb29nbGUuY29tIiwicXVlcnkiOnsiZXhhbXBsZSI6MSwidGVzdCI6Mn19
 *
 * */
export const encodeRoute = (url: Url): string => {
  return btoa(JSON.stringify(url));
};

/*
 * encodedRoute = eyJwYXRobmFtZSI6Imh0dHA6Ly9nb29nbGUuY29tIiwicXVlcnkiOnsiZXhhbXBsZSI6MSwidGVzdCI6Mn19
 *
 * -> UrlRoute:
 * {
 *   pathname: 'http://google.com',
 *   query: { example: 1, test: 2 }
 * }
 *
 * */
export const decodeRoute = (encodedRoute: string): Url => {
  return JSON.parse(atob(encodedRoute));
};

// Url: http://google.com?example=1&test=2 -> UrlId: aHR0cDovL2dvb2dsZS5jb20/ZXhhbXBsZT0xJnRlc3Q9Mg==
export const urlToUrlId = (url: string): string => {
  return btoa(encodeURI(url || window.location.href));
};

// UrlId: aHR0cDovL2dvb2dsZS5jb20/ZXhhbXBsZT0xJnRlc3Q9Mg== -> Url: http://google.com?example=1&test=2
export const urlIdToUrl = (encodedUrl: string): string => {
  if (!encodedUrl) {
    return '';
  }

  return decodeURI(atob(encodedUrl));
};
