import { css } from 'styled-components';

import { innerBorder } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '../../colors-v2';

const colorNeutral = css`
  &.color-neutral {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.neutral180}`)}
        color: ${colorsV2.neutral180};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.neutral10}`)}
          background-color: transparent;
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.neutral20}`)}
        color: ${colorsV2.neutral160};
        background-color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.neutral40}`)}
          color: ${colorsV2.neutral160};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral20};
          color: ${colorsV2.neutral160};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.neutral10}`)}
          background-color: transparent;
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.neutral180}`)}
        color: ${colorsV2.neutral180};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.neutral20}`)}
          background-color: transparent;
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.neutral40}`)}
        color: ${colorsV2.neutral160};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.neutral60}`)}
          color: ${colorsV2.neutral160};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.neutral60};
          color: ${colorsV2.neutral160};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.neutral20}`)}
          background-color: transparent;
          color: ${colorsV2.neutral60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.blackTransparent60}`)}
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.blackTransparent30}`)}
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blackTransparent30};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.blackTransparent10}`)}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.blackTransparent20}`)}
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.blackTransparent40}`)}
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blackTransparent60};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.blackTransparent10}`)}
          background-color: transparent;
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorWhite = css`
  &.color-white {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray10}`)}
        background-color: ${colorsV2.white100};
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray20}`)}
        background-color: ${colorsV2.white100};
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.gray40}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray40};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.white20}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.white30};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorGray = css`
  &.color-gray {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray20}`)}
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.gray40}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray20};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray40}`)}
        color: ${colorsV2.gray160};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.gray60}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray40};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.bt20}`)}
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.bt40}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.bt60};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.blue100}`)}
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue120};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.blue20}`)}
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.blue40}`)}
          background-color: ${colorsV2.blue10};
        }

        &.active,
        &:active {
          ${innerBorder(`1px solid ${colorsV2.blue60}`)}
          background-color: ${colorsV2.blue10};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.blue100}`)}
        color: ${colorsV2.blue100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue120};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.blue140}`)}
        color: ${colorsV2.blue80};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue140};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.blue160};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorBlack = css`
  &.color-black {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray180}`)}
        color: ${colorsV2.gray180};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray160};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.gray180}`)}
        color: ${colorsV2.gray180};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray180};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.gray160};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.bt60}`)}
        color: ${colorsV2.white100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.bt30}`)}
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.bt30};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.primary100}`)}
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary120};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.primary20}`)}
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.primary40}`)}
          background-color: ${colorsV2.primary5};
        }

        &.active,
        &:active {
          ${innerBorder(`1px solid ${colorsV2.primary60}`)}
          background-color: ${colorsV2.primary10};
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.primary100}`)}
        color: ${colorsV2.primary100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary120};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.primary140}`)}
        color: ${colorsV2.primary80};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary140};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.primary160};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;
const colorPurple = css`
  &.color-purple {
    &.background-light {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.purple100}`)}
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple120};
          color: ${colorsV2.white100};
        }

        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray10}`)}
          background-color: transparent;
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }

      &.color-style-matte {
        ${innerBorder(`1px solid ${colorsV2.purple20}`)}
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder(`1px solid ${colorsV2.purple40}`)}
          background-color: ${colorsV2.purple5};
        }

        &.active,
        &:active {
          ${innerBorder(`1px solid ${colorsV2.purple60}`)}
          background-color: ${colorsV2.purple10};
        }
      }
    }

    &.background-gray {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.purple100}`)}
        color: ${colorsV2.purple100};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple100};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple120};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.gray20}`)}
          color: ${colorsV2.gray60};
          cursor: not-allowed;
        }
      }
    }

    &.background-dark {
      &.color-style-bold {
        ${innerBorder(`1px solid ${colorsV2.purple140}`)}
        color: ${colorsV2.purple80};

        &:hover {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple140};
          color: ${colorsV2.white100};
        }

        &.active,
        &:active {
          ${innerBorder('none')}
          background-color: ${colorsV2.purple160};
          color: ${colorsV2.white100};
        }
        &.disabled {
          ${innerBorder(`1px solid ${colorsV2.bt10}`)}
          color: ${colorsV2.white20};
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const outlineStyles = css`
  &.outline {
    background-color: transparent;

    ${colorPrimary}
    ${colorBlue}
    ${colorBlack}
    ${colorWhite}
    ${colorGray}
    ${colorNeutral}
    ${colorPurple}
  }
`;
