/* eslint-disable jsx-a11y/anchor-has-content */

import { noop } from 'lodash';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { NextRouter } from 'next/router';
import * as React from 'react';

interface GoBackParams {
  router: NextRouter;
  ifEmptyHistory: () => void;
}

export const pageGoBack = ({ router, ifEmptyHistory = noop }: GoBackParams) => {
  if (document.referrer === '') {
    ifEmptyHistory();
    return;
  }
  router.back();
};

export interface HyperLinkProps
  extends Omit<NextLinkProps, 'onMouseEnter' | 'onTouchStart'>,
    React.HtmlHTMLAttributes<HTMLAnchorElement> {
  children?: any;
  target?: string;
  rel?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}

export const Link = (props: HyperLinkProps) => {
  const {
    disabled = false,
    href = '#',
    as,
    replace,
    scroll = true,
    shallow,
    passHref,
    prefetch = false,
    locale,
    ...rest
  } = props;

  return (
    <NextLink
      href={disabled ? '#' : href}
      as={as}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref={passHref}
      prefetch={prefetch}
      locale={locale}
    >
      <a
        {...rest}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
            return;
          }
          if (rest?.onClick) {
            rest.onClick(e);
          }
        }}
      />
    </NextLink>
  );
};
